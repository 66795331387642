import React from "react";
import { Fade } from "react-awesome-reveal";

const ContactSection = () => {
    
    const mail = "info@snpstech.in";
    const phoneNumber = "+080 6218 0291";

    return(
        <>
         {/*=============== CONTACT US ===============*/}
         <section className="contact section container" id="contact">
         <Fade direction='down'>
             <div className="contact__container grid">
                 <div className="contact__content">
                     <h2 className="section__title-center">
                         Contact Us From <br />
                         Here
                     </h2>
                     <p className="contact__description">
                         You can contact us from here, you can write to us, call us or visit
                         our service center, we will gladly assist you.
                     </p>
                 </div>
                 <ul className="contact__content grid">
                     <li className="contact__address">
                         Telephone:
                         <span className="contact__information"><a href={`tel:${phoneNumber}`} style={{ color: '#FDC212' }}>  {phoneNumber}</a></span>
                     </li>
                     <li className="contact__address">
                         Email:
                         <span className="contact__information"><a href={`mailto:${mail}`} style={{ color: '#FDC212' }}> {mail}</a></span>
                     </li>
                     <li className="contact__address">
                         <a target='blank' href='https://www.google.com/maps?q=20.2131795,85.6998743'>
                             Location: <span className="contact__information">Plot No- 502/1237, Ogalapada, Industrial Estate, Janla, Bhubaneswar, Odisha 752054</span>
                         </a>
                     </li>
                 </ul>
                 <div className="contact__content">
                     <a href="/contact" className="button">
                         Contact Us
                     </a>
                 </div>
             </div>
         </Fade>
     </section>
     </>
    );
}

export default ContactSection;